<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    show: boolean
  }>(),
  {
    show: false
  }
)
const emits = defineEmits<{
  'update:modelValue': [value: boolean]
  close: [value: boolean]
}>()
const close = () => emits('update:modelValue', false)
</script>

<template>
  <van-overlay :show="show" style="z-index: 9999" @click="close">
    <div h-screen flex items-center justify-center @click.stop>
      <div flex items-center justify-center>
        <van-loading vertical h-25 w-25 bg-3b405a ct-ffffff size="24px" />
      </div>
    </div>
  </van-overlay>
</template>

<style scoped>
:deep(.van-loading--vertical) {
  justify-content: center;
}
</style>
